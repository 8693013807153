/* eslint-disable no-extra-boolean-cast */
import { SolidityMultiClaimContract } from '@/blockchainHandlers/multiclaim-contract-solidity'
import { Zero } from '@/constants'
import { action, observable } from 'mobx'

export class WinnerListStore {
  @observable poolId: any
  @observable transNumber = 0
  @observable totalAmount = Zero
  @observable estimateGas = Zero
  @observable codeMirrorText = ''
  @observable editorData = []
  @observable pool: any
  @observable multiclaimHandler?: SolidityMultiClaimContract

  constructor() {
    //
  }

  @action.bound changeState(state) {
    if (!!state.poolId) this.poolId = state.poolId
    if (!!state.transNumber) this.transNumber = state.transNumber
    if (!!state.estimateGas) {
      this.estimateGas = state.estimateGas
    }
    if (!!state.totalAmount) this.totalAmount = state.totalAmount
    if (!!state.codeMirrorText) this.codeMirrorText = state.codeMirrorText
    if (!!state.editorData) this.editorData = state.editorData
    if (!!state.pool) this.pool = state.pool
    if (!!state.multiclaimHandler) this.multiclaimHandler = state.multiclaimHandler
  }

  @action resetData(poolId) {
    this.poolId = poolId
    this.clearCache
  }
  @action clearCache() {
    this.poolId = undefined
    this.transNumber = 0
    this.totalAmount = Zero
    this.estimateGas = Zero
    this.codeMirrorText = ''
    this.editorData = []
    this.pool = undefined
    this.multiclaimHandler = undefined
  }
}

export const winnerListStore = new WinnerListStore()
