/* eslint-disable no-extra-boolean-cast */
import { computed, action, reaction, IReactionDisposer, observable, runInAction } from 'mobx'
import { walletStore } from '@/stores/wallet-store'
import { asyncAction } from 'mobx-utils'
import { SolidityMultiClaimContract } from '@/blockchainHandlers/multiclaim-contract-solidity'
import { blockchainHandler } from '@/blockchainHandlers'
import { VestingScheduler } from '@/blockchainHandlers/multiclaim-contract-interface'
import { winnerListStore } from '../stores/add-winner-list-store'
import { clone, cloneDeep } from 'lodash'
import { bnHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Zero } from '@/constants'

export default class ClaimerDetailOwnerViewModel {
  @observable pool: any = null
  @observable poolId: any

  @observable isShowEditPoolNameDialog = false
  @observable isShowEditAvatarDialog = false
  @observable isShowEditDescriptionDialog = false
  @observable isShowEditCoverUrlDialog = false
  @observable isShowEditAvatarUrlDialog = false
  @observable isShowAddWinnerDialog = false
  @observable isShowPauseDialog = false
  @observable isShowUnpauseDialog = false
  @observable isShowClosePoolDialog = false
  @observable editDialogLoading = false
  @observable searchWalletText = ''
  @observable loadingPool = false

  multiclaimHandler?: SolidityMultiClaimContract

  _disposers: IReactionDisposer[] = []

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.account,
        async () => {
          if (walletStore.connected && this.poolId) {
            await this.loadPool(this.poolId)
          }
        }
      )
    ]
  }
  destroy() {
    this._disposers.forEach(d => d())
  }

  @asyncAction *loadPool(id) {
    this.poolId = id
    try {
      this.loadingPool = true
      if (!walletStore.connected) return
      winnerListStore.resetData(this.poolId)
      yield this.loadData()
      this.pool = yield this.multiclaimHandler!.fetchClaimPoolDetailOwner(id, walletStore.account)
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.loadingPool = false
    }
  }

  @asyncAction *loadData() {
    this.multiclaimHandler = blockchainHandler.multiclaimContractFactory({
      chainId: walletStore.chainId
    }) as SolidityMultiClaimContract
    this.multiclaimHandler.injectProvider()
    yield this.multiclaimHandler.init()
  }

  @action changeEditDialogLoading(value: boolean) {
    this.editDialogLoading = value
  }

  @asyncAction *onEditWinner(winner) {
    yield this.multiclaimHandler?.addWinnerList(
      walletStore.account,
      this.poolId,
      [winner.address],
      [`${winner.value}`],
      this.decimals
    )
    const address = winner.address.toLowerCase()
    runInAction(() => {
      let isExist = false
      const poolClone = cloneDeep(this.pool) as any
      ;(poolClone.winnerInfos || []).map(item => {
        if (item.address.toLowerCase() === address) {
          poolClone.amount = poolClone.amount.addUnsafe(FixedNumber.from(`${winner.value}`)).subUnsafe(item.amount)
          isExist = true
          item.amount = FixedNumber.from(`${winner.value}`)
        }
        return item
      })
      if (!isExist) {
        poolClone.winnerInfos.push({
          address: winner.address,
          amount: FixedNumber.from(`${winner.value}`)
        })
        poolClone.amount = poolClone.amount.addUnsafe(FixedNumber.from(`${winner.value}`))
      }
      this.pool = poolClone
    })
  }

  @asyncAction *onChangeDescription(description) {
    yield this.multiclaimHandler?.changeDescription(walletStore.account, this.poolId, description)
    this.changePool({ description })
  }
  @asyncAction *onChangeAvatarUrl(avatarUrl) {
    yield this.multiclaimHandler?.changeAvatarUrl(walletStore.account, this.poolId, avatarUrl)
    this.changePool({ avatarUrl })
  }
  @asyncAction *onChangeCoverUrl(coverUrl) {
    yield this.multiclaimHandler?.changeCoverUrl(walletStore.account, this.poolId, coverUrl)
    this.changePool({ coverUrl })
  }
  @asyncAction *onChangePoolName(name) {
    yield this.multiclaimHandler?.changeName(walletStore.account, this.poolId, name)
    this.changePool({ name })
  }

  @asyncAction *onClosePool() {
    yield this.multiclaimHandler?.closeClaim(walletStore.account, this.poolId)
    this.changePool({ closed: true })
  }

  @asyncAction *onPause() {
    yield this.multiclaimHandler?.pause(walletStore.account, this.poolId)
    this.changePool({ paused: true })
  }
  @asyncAction *onUnpause() {
    yield this.multiclaimHandler?.unpause(walletStore.account, this.poolId)
    this.changePool({ paused: false })
  }
  @action searchInputChange(value) {
    this.searchWalletText = value.trim().toLowerCase()
  }

  @action changePool(state) {
    const poolClone = cloneDeep(this.pool) as any
    if (!this.isEmpty(state.description)) {
      poolClone.description = state.description
    }

    if (!this.isEmpty(state.avatarUrl)) {
      poolClone.avatarUrl = state.avatarUrl
    }
    if (!this.isEmpty(state.coverUrl)) {
      poolClone.coverUrl = state.coverUrl
    }
    if (!this.isEmpty(state.name)) {
      poolClone.name = state.name
    }
    if (!this.isEmpty(state.paused)) {
      poolClone.paused = state.paused
    }
    if (!this.isEmpty(state.closed)) {
      poolClone.closed = state.closed
    }
    this.pool = poolClone
  }

  isEmpty(value) {
    return value == null
  }

  @action requestAddWinnerDialog(value) {
    this.isShowAddWinnerDialog = value
  }
  @action requestEditDescriptionDialog(value) {
    this.isShowEditDescriptionDialog = value
  }
  @action requestEditCoverUrlDialog(value) {
    this.isShowEditCoverUrlDialog = value
  }
  @action requestEditPoolNameDialog(value) {
    this.isShowEditPoolNameDialog = value
  }
  @action requestEditAvatarDialog(value) {
    this.isShowEditAvatarDialog = value
  }
  @action requestPauseDialog(value) {
    this.isShowPauseDialog = value
  }
  @action requestUnpauseDialog(value) {
    this.isShowUnpauseDialog = value
  }
  @action requestClosePoolDialog(value) {
    this.isShowClosePoolDialog = value
  }
  @computed get poolName() {
    return this.pool?.name
  }
  @computed get description() {
    return this.pool?.description
  }
  @computed get poolAmount() {
    return this.pool?.amount || Zero
  }

  @computed get decimals() {
    return this.pool?.tokenInfo?.decimals || 18
  }

  @computed get isNoWallet() {
    return this.winnerInfos.length <= 0
  }
  // @computed get winnerList() {
  //   return this.pool?.winnerList || []
  // }
  @computed get winnerInfos() {
    return this.pool?.winnerInfos || []
  }
  @computed get tokenName() {
    return this.pool?.tokenInfo?.symbol
  }
  @computed get coverUrl() {
    return this.pool?.coverUrl
  }
  @computed get avatarUrl() {
    return this.pool?.avatarUrl
  }
  @computed get vestingScheduler(): VestingScheduler[] {
    return this.pool?.vestingScheduler || []
  }
  @computed get validWinnerInfos() {
    return this.winnerInfos.filter(item => item.address.toLowerCase().includes(this.searchWalletText))
  }
  @computed get isPaused() {
    return this.pool?.paused || false
  }
  @computed get isClosed() {
    return this.pool?.closed || false
  }
}
