

































































































































































































































// import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import ClaimerDetailOwnerViewModel from '../viewmodels/claimer-detail-owner-viewmodel'

@Observer
@Component({
  components: {
    HoverEditCardText: () => import('../components/hover-edit-card-text.vue'),
    EditWinnerListActions: () => import('../components/edit-winner-list-actions.vue'),
    AddWinnerDialog: () => import('../dialogs/add-winner-dialog.vue'),
    EditDescriptionDialog: () => import('../dialogs/edit-description-dialog.vue'),
    EditPoolNameDialog: () => import('../dialogs/edit-pool-name-dialog.vue'),
    PausePoolDialog: () => import('../dialogs/pause-pool-dialog.vue'),
    UnpausePoolDialog: () => import('../dialogs/unpause-pool-dialog.vue'),
    ClosePoolDialog: () => import('../dialogs/close-pool-dialog.vue'),
    EditAvatarUrlDialog: () => import('../dialogs/edit-avatar-url-dialog.vue'),
    EditCoverUrlDialog: () => import('../dialogs/edit-cover-url-dialog.vue')
  }
})
export default class Claimer extends Vue {
  @Provide() vm = new ClaimerDetailOwnerViewModel()
  walletStore = walletStore
  vestingSchedulerHeaders = [
    {
      text: '#',
      align: 'left',
      sortable: false,
      value: 'stt'
    },
    {
      text: 'Date',
      align: 'left',
      sortable: false,
      value: 'date'
    },
    {
      text: 'Claim Amount (%)',
      align: 'center',
      sortable: false,
      value: 'percentage'
    },
    {
      text: 'Total Allocation',
      align: 'center',
      sortable: false,
      value: 'amount'
    }
  ]

  winnerInfosHeaders = [
    {
      text: '-',
      align: 'left',
      sortable: false,
      value: 'stt'
    },
    {
      text: 'Wallet address',
      align: 'left',
      sortable: false,
      value: 'address'
    },
    {
      text: 'Amount',
      align: 'right',
      sortable: false,
      value: 'amount'
    },
    {
      text: '',
      align: 'right',
      sortable: false,
      value: 'edit'
    }
  ]

  destroyed() {
    this.vm.destroy()
  }

  copyLink() {
    navigator.clipboard.writeText(
      `${process.env.VUE_APP_END_POINT}/claimer/pools/user/?chainId=${walletStore.chainId}&pool=${this.vm.poolId}`
    )
  }

  @Watch('$route.query', { immediate: true }) async loadPool(value) {
    if (value.pool) {
      await this.vm.loadPool(value.pool)
      if (!this.vm.pool) this.$router.push('/claimer/pools')
    } else {
      this.$router.push('/claimer/pools')
    }
  }
}
